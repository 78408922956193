import * as i18n from 'react-i18next'

import { SLUG_PATTERN } from 'utils/regex'

import { extractContextMessage, getViolations } from './helpers'

export const getErrorMessages = (error: Error): string[] => {
    const message = error?.response?.data?.['hydra:description']
    const contextMessage = extractContextMessage(message)

    const t = i18n?.getI18n().t

    if (!t) return []

    if (contextMessage !== null) {
        return [t('additional:' + contextMessage.message, contextMessage.context)]
    }

    const violations = getViolations(error)

    if (violations) {
        return violations.map(({ message }) => {
            const contextMessage = extractContextMessage(message)

            if (contextMessage !== null) {
                return t('additional:' + contextMessage.message, contextMessage.context)
            }

            return t('additional:' + message)
        })
    }

    return [t(
        message && SLUG_PATTERN.test(message)
            ? `additional:${message}`
            : 'exceptions.internal_error'
    )]
}
/**
 * Extracts the value of a given property in "hydra:description".
 * The error response may contain a prefix (e.g., "int(1)\n") before the JSON.
 * @param errorData Raw string returned by the backend.
 * @param selectedProperty Desired property (e.g., "companies").
 * @returns The found value or an empty string.
 */
export function extractHydraProperty (
    errorData: string,
    selectedProperty?: string,
    delimiter = ' '
): string {
    if (!errorData) return ''
    try {
        // If a prefix is present, keep only the JSON part
        const jsonStart = errorData.indexOf('{')
        const jsonStr = jsonStart === -1 ? errorData : errorData.substring(jsonStart)
        const parsedData = JSON.parse(jsonStr)
        if (selectedProperty && parsedData[selectedProperty]) {
            const prop = parsedData[selectedProperty]
            return Array.isArray(prop) ? prop.join(delimiter) : prop
        }
    } catch (e) {
        console.error('Error extracting Hydra property:', e)
    }
    return ''
}
